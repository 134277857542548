<template>
    <div class="item-delivery-card">
        <div class="card ">
            <div class="card-body">
                <div class="">
                    <div class="d-flex justify-content-between">
                        <div class=""><strong>Quantity #: </strong> <span class="fw-bold fs-5">{{ item.quantity}}</span> </div>
                        <div>
                            <button v-if="item.status == 'confirmed'" class="btn me-2 btn-sm btn-primary"
                             @click="showEvidence = true"
                            type="button"> <i class="mdi mdi-image-search align-bottom"></i></button>
    
                            <button class="btn btn-sm btn-outline-primary" @click="downloadReceipt(item.id)"
                            type="button"> Receipt</button>
                        </div>
                    </div>
                    <hr class="mt-1 mb-2">
                    <div class="row g-2 align-items-center">
                        <div class="col-12">
                            <div class=""><strong>Status: </strong> <span v-html="statusBadge(item.status)"></span></div>
                        </div>
                        <div class="col-6">
                            <div class=""><strong>Package: </strong> <span> {{ item.unit_title }}</span></div>
                        </div>
                        <div class="col-6">
                            <div class=""><strong>Package Count: </strong> <span> {{ item.unit_count }}</span></div>
                        </div>
                        <div class="col-12">
                            <div class=""><strong>Date: </strong> {{ $filters.date(item.created_at, 1) }}</div>
                        </div>
                        <div v-if="item.dispatched_remark" class="col-12">
                            <div class=""><strong>Dispatched Remark: </strong> <span class="">{{ item.dispatched_remark}}</span> </div>
                        </div>
                    </div>
                    <div v-if="item.confirmer" class="mt-2">
                        <hr class="my-1">
                        <div>
                            <p class="small text-muted d-flex justify-content-between mb-1">
                                <span>Confirmed By</span> <span class="">{{ $filters.date(item.confirmed_at) }}</span>
                            </p>
                            <h3 class="card-title mb-1">
                            <span>
                                {{item.confirmer.first_name}} {{item.confirmer.last_name}}
                            </span>
                            </h3>
                            <p class="mb-1">{{item.confirmer.mobile}}</p>
                            <div v-if="item.confirmed_remark">
                                <div class=""><strong>Confirmed Remark: </strong> <span class="">{{ item.confirmed_remark}}</span> </div>
                            </div>
                        </div>
                    </div>
                </div>
                <slot name="actions"></slot>
            </div>
        </div>
        <b-modal hide-footer v-model="showEvidence" centered
            title="Uploaded delivery photo" @hide="showEvidence = false">
            <img class="w-100 type-sample" :src="absoluteUrl(item.image)" />
        </b-modal>
    </div>
    </template>
    
    <script>
    export default {
        props:{
            item:{
                type: Object,
                required: true
            }
        },
        data(){
            return {
                showEvidence: false
            }
        },
        methods:{
            downloadReceipt(itemId) {
                const payload = {
                    url: `${this.absoluteUrl()}/api/download-receipt`,
                    data: {  model_id: btoa(itemId), receipt: '676c59bc_9daa_4935_8f54_c419d2c325e5' }
                }
                this.$store.dispatch("changeLoaderValue", true)
                this.serverReceipt(payload)
                    .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.status != 200){
                        this.alertError('Could not generate receipt');
                    }
                })
            }, 
        }
    }
    </script>
    
    <style>
    </style>

<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="item" class="col-12">
      <h5 class="mb-3">Allocated item Details</h5>
      <div class="card overflow-hidden">
        <div class="card-body">
          <div class="row g-2 gx-lg-4 align-items-center">
            <div class="col-12">
              <div class=""><strong>Item ID #: </strong> {{ item.id }}</div>
            </div>
            <div class="col-sm-6">
              <div class=""><strong>Allocated Quantity #: </strong> <span class="fw-bold fs-5">{{ item.allocated_quantity}}</span> </div>
            </div>
            <div class="col-sm-6">
              <div class=""><strong>Undispatched quantity #: </strong> <span class="fw-bold fs-5">{{ item.undispatched_quantity}}</span> </div>
            </div>
            <div class="col-sm-6">
              <div class=""><strong>Confirmed Quantity #: </strong> <span class="fw-bold fs-5">{{ item.confirmed_quantity}}</span> </div>
            </div>
            <div class="col-sm-6">
              <div class=""><strong>Pending Quantity #: </strong> <span class="fw-bold fs-5">{{ item.pending_quantity}}</span> </div>
            </div>
            <div class="col-12">
              <div class=""><strong>Date Allocated: </strong> {{ $filters.date(item.created_at, 1) }}</div>
            </div>
            <div class="col-12">
              <hr class="my-1">
            </div>
            <div class="col-12">             
              <h6>Medicine</h6>
              <p v-if="item.medicine" class="mb-0"> <strong>{{item.medicine.name}}</strong> - {{item.medicine.description}}</p>
            </div>
            <div class="col-12">
              <hr class="my-1">
            </div>
            <div class="col-12">
              <div class=""><strong>Hospital </strong></div>
              <div v-if="item.hospital">
                  {{ item.hospital.name }}
                  <small>
                      <span v-if="item.hospital" class="mb-0">
                      {{item.hospital.ward.name}}
                      </span>
                      <span v-if="item.hospital.lga">,  
                      {{item.hospital.lga.name}}
                      </span>
                  </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5>{{ item.medicine.name }} Deliveries</h5>
      <div class="row">
        <div v-for="(delivery, dIndex) in item.deliveries"
           :key="dIndex" class="col-md-6">
          <item-delivery-card :item="delivery" >
            <template v-slot:actions>
              <div class="">
               
              </div>
            </template>
          </item-delivery-card>
        </div>
      </div>
      <div v-if="!item.deliveries.length" class="">
        <h6>No Delivery</h6>
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import ItemDeliveryCard from '@/components/cards/ItemDeliveryCard.vue'

export default {
  name: "allocated-item-show",
  components:{
    IsLoading,
    ItemDeliveryCard,
  },
  data() {
    return {
      isLoading: true,
      item: null
    }
  },
  watch: {
    '$route.params.itemId': function (id){
      if(id) this.fetchItem()
    },
  },
  methods: {
    updateItem(item){
      Object.assign(this.item, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/allocated-items/${this.$route.params.itemId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.item = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    initializeAll(){
  
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

